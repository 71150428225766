import React, { useState, useEffect } from "react";

// import Navbar from ".//components/Navbar.js";
import Footer from ".//components/Footer.js";
import ContactForm from "./ContactForm";

export default function Landing() {
  return (
    <>
      <main>
        <div
          id="Home"
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <div className="absolute top-0 w-full h-full bg-center bg-cover">
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            >
              <source src="progam1.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="container relative mx-auto mt-11">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center pt-9 pr-1">
                <div className="content-center items-center justify-center">
                  <h1 className="text-white font-semibold text-6xl content-center items-center justify-center font-mono">
                    FlowKAI
                  </h1>
                  <p className="mt-4 text-2xl font-semibold text-white content-center items-center justify-center">
                    Transforming Enterprises Through Technology
                    {/* <Typewriter
                      text="Through Technology"
                      delay={250}
                      infinite
                    /> */}
                  </p>
                  <p className="mt-4 text-lg font-normal text-gray-300 content-center items-center justify-center">
                    We believe in the power of technology to drive business
                    success in the AI revolution.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          ></div>
        </div>

        <section id="Services" className="pb-2 bg-slate-50">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap mt-32">
              <div className="w-full px-4 ml-auto mr-auto text-center">
                <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 mt-8 shadow-lg rounded-full bg-pink-300">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>

                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Our Tech Solutions will make your business rise above the
                  rest:
                </h3>

                <div className="container mx-auto px-1">
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-4/12 px-4 text-center">
                      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                        <div className="px-4 py-5 flex-auto">
                          <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                            <i className="fas fa-retweet"></i>
                          </div>
                          <h6 className="text-xl font-semibold">
                            AI Tailored Technology
                          </h6>
                          <p className="mt-2 mb-4 text-gray-600">
                            Artificial intelligence has the potential for your
                            business to transform operations, enhance
                            decision-making and increase efficiency
                            exponentially.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                        <div className="px-4 py-5 flex-auto">
                          <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                            <i className="fas fa-fingerprint"></i>
                          </div>
                          <h6 className="text-xl font-semibold">
                            Data Solutions
                          </h6>
                          <p className="mt-2 mb-4 text-gray-600">
                            Harness the power of data to gain actionable
                            insights, make informed decisions in real time, and
                            drive your business forward.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                      <div
                        id="customsoftware"
                        className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
                      >
                        <div className="px-4 py-5 flex-auto">
                          <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                            <i className="fas fa-award"></i>
                          </div>
                          <h6 className="text-xl font-semibold">
                            Custom Software
                          </h6>
                          <p className="mt-2 mb-4 text-gray-600">
                            Tailored software solutions that address your
                            business and customer needs.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      id="websolutions"
                      className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center"
                    >
                      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                        <div className="px-4 py-5 flex-auto">
                          <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-yellow-300">
                            <i className="fas fa-award"></i>
                          </div>
                          <h6 className="text-xl font-semibold">
                            Web Solutions
                          </h6>
                          <p className="mt-2 mb-4 text-gray-600">
                            Web application development and mobile apps, we
                            design and build web solutions that captivate your
                            audience and elevate your online presence.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      id="benchmarking"
                      className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center"
                    >
                      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                        <div className="px-4 py-5 flex-auto">
                          <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-indigo-300">
                            <i className="fas fa-award"></i>
                          </div>
                          <h6 className="text-xl font-semibold">
                            Benchmarking
                          </h6>
                          <p className="mt-2 mb-4 text-gray-600">
                            Unlock the power to compare your performance with
                            the market, conduct thorough market analysis, and
                            make informed decisions based on current market
                            trends with us.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      id="techsupport"
                      className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center"
                    >
                      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                        <div className="px-4 py-5 flex-auto">
                          <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-gray-300">
                            <i className="fas fa-award"></i>
                          </div>
                          <h6 className="text-xl font-semibold">
                            Tech Support & Training
                          </h6>
                          <p className="mt-2 mb-4 text-gray-600">
                            Elevate productivity through seamless incorporation
                            of solutions. With the right support and
                            comprehensive training, empower your team to operate
                            at peak efficiency.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <a
                  href="https://logoChe AIa1c2dccdc4d1d30552b07f.vercel.app/how-it-works"
                  className="font-bold text-gray-800 mt-8"
                >
                  Check How It Works!
                </a> */}
              </div>
            </div>
          </div>
        </section>

        <section id="Meetourteam" className="pt-20 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h1 className="text-4xl font-semibold">Meet our Team</h1>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 lg:w-6/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    //src="https://media.licdn.com/dms/image/C5603AQH3bP5iKeQFwQ/profile-displayphoto-shrink_800_800/0/1651137568002?e=1712793600&v=beta&t=O8kt7Mc0ppuFlIo8oMKH4rr73UnFvzbEmjliiQMng2E"
                    src="Chris.jpg"
                    className="shadow-lg rounded-full max-w-full mx-auto"
                    style={{ maxWidth: "120px" }}
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Christopher Rodriguez</h5>
                    <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                      Software Engineer - Founder
                    </p>
                    <p className="mt-1 text-sm text-black font-medium">
                      Frontend developer and UX engineer consulting in the
                      advertising industry and across public service agencies.
                      Skills include user centered design, UX engineering,
                      javascript frameworks (react, vue), UI Design, wireframes,
                      rapid prototyping, usability testing, CMS integration and
                      mentoring
                    </p>
                    <div className="mt-6">
                      <a href="https://www.linkedin.com/in/christopher1111/">
                        <button
                          id="linkedinChris"
                          className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        >
                          <i className="fab fa-twitter"></i>
                        </button>
                        <button
                          className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </button>
                        <button
                          className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                        >
                          <i className="fab fa-dribbble"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-6/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    // src ="https://media.licdn.com/dms/image/C5603AQGPkmT4sqK6KA/profile-displayphoto-shrink_200_200/0/1601793092036?e=1712793600&v=beta&t=PkFw9ezmU8F4FUrcvdqEYIkrEw7G49TRKOCuK8qXyto"
                    src="Juan.jpg"
                    className="shadow-lg rounded-full max-w-full mx-auto"
                    style={{ maxWidth: "120px" }}
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Juan G. Biestro</h5>
                    <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                      Data & BI Specialist - Founder
                    </p>
                    <p className="mt-1 text-sm text-black font-medium">
                      Data Engineer specializing in data transformation,
                      pipeline creation, and automation. Proficient in SQL,
                      BigQuery, Python, and Airflow, I excel in designing and
                      implementing robust data solutions.
                    </p>
                    <div className="mt-6">
                      <a href="https://www.linkedin.com/in/juan-biestro-2664473a/">
                        <button
                          className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                        >
                          <i className="fab fa-twitter"></i>
                        </button>
                        <button
                          className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </button>
                        <button
                          className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                        >
                          <i className="fab fa-dribbble"></i>
                        </button>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Step into the future with us as we create innovative
                  solutions!
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-white">
                  Transform your Business with assurance. Our straightforward
                  commitment is to elevate your business using AI, automation,
                  digitalization, and real-time data for a distinct competitive
                  advantage.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-blue-200 inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Innovative Solutions
                </h6>
                <p className="mt-2 mb-4 text-white">
                  Get most out of your business data with our AI solutions
                  tailored to your needs.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-green-200 inline-flex items-center justify-center">
                  <i className="fas fa-poll text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Confidence in Transformation
                </h5>
                <p className="mt-2 mb-4 text-white">
                  Guiding businesses with confidence through comprehensive AI,
                  automation, and digitalization, ensuring a seamless and
                  impactful journey towards the next level.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-red-200 inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  We've got your back
                </h5>
                <p className="mt-2 mb-4 text-white">
                  Empowering clients with real-time data integration, granting
                  them a competitive edge in the dynamic business landscape
                  through strategic technological advancements.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          id="contactForm"
          className="relative block py-24 lg:pt-0 bg-gray-900"
        >
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 my-10">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
