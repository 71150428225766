import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Landing from "./Home";
import Navbar from "./components/Navbar";
import ContactForm from "./ContactForm";
import AbnLookUp from "./AbnLookup";
import Login from "./Login";
import Navigation from "./FullscreenResults";
import AbnTablePreview from "./TableResults";
import Logout from "./LogOut";
import LineChart from "./graphs/AbsChart";
import DualAxisLineChart from "./graphs/AbsChartCorrelation";

const Home = () => (
  <div>
    <Navbar transparent />
    {/* <Header /> */}
    <Landing />
  </div>
);

const App = () => {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-WC44FKNDTL";
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-WC44FKNDTL');
    `;
    document.head.appendChild(script2);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-form" element={<ContactForm />} />
        <Route path="/abn-lookup" element={<AbnLookUp />} />
        <Route path="/full-screen-results" element={<Navigation />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/cpi-monthly" element={<LineChart />} />
        <Route path="/quarterly-correlation" element={<DualAxisLineChart />} />

        {/* <Route path="/abn-table-preview" element={<AbnTablePreview />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
